<template>
  <div>
  <div style="background: no-repeat center center / cover" :style="{ backgroundImage: 'url(' + require('@/assets/breadcumb.jpg') + ')' }" class="breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="breadcumb-title">{{ langContent.contact }} </h2>
            <ul class="breadcumb-menu">
              <li>
                <router-link to="/">{{ langContent.home }}</router-link>
              </li>
              <li>{{ langContent.contact }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-style2-area pb--90">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="section-title">
              <h2>{{ langContent.contact }}</h2>
            </div>
          </div>
        </div>
        <div class="contactCard">

          <div class="adres">
            <div class="cons_about_content">
              <h6 class="title_top">{{langContent.addr1}}</h6>
              <p>{{ contact.addr }}</p>
            </div>
            <div class="cons_about_content">
              <h6 class="title_top">{{langContent.addr2}}</h6>
              <p>{{ contact.addr2 }}</p>
            </div>
          </div>
          <div class="tel">
            <div class="cons_about_content">
              <h6 class="title_top">{{langContent.phone}}</h6>
              <p><a target="_blank" :href="'tel:'+contact.phone">{{ contact.phone }}</a></p>
              <h6 class="title_top">{{langContent.mail}}</h6>
              <p><a target="_blank" :href="'mailto:'+contact.mail">{{ contact.mail }}</a></p>
            </div>
          </div>
          <div class="mail">
            <div class="cons_about_content" v-for="item in contactmail">
              <h6 class="title_top">{{item.text}}</h6>
              <p><a target="_blank" :href="'mailto:'+item.mail">{{ item.mail }}</a></p>
            </div>
          </div>
        </div>

        <div class="row mt--40">
          <div class="col-12">
            <iframe
                allowfullscreen=""
                height="450"
                loading="lazy"
                :src="contact.maps"
                style="border:0;"
                width="100%">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosCustom from "../../axiosCustom";

export default {
  data() {
    return {
      contact: [],
      contactmail: [],
      langContent: [],
    }
  },
  created() {

    axiosCustom
        .get('langfix')
        .then(response => {
          this.langContent = response.data[0]
          document.title = 'Delta Plastik | '+response.data[0].contact
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('contactmail')
        .then(response => {
          this.contactmail = response.data
        })
        .catch(error => {
          console.log(error);
        })
  }
}
</script>
<style>
.contactCard{
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  position: relative;
  color: #fff;
}
.contactCard a,
.contactCard p{
  color: #000;
}
.contactCard>div{
  flex-grow: 1;
  width: 32%;
  background: #ffffff;
  padding: 20px;
  box-shadow: rgba(200, 200, 200, .1) 6px 2px 16px 0px, rgba(200, 200, 200, .3) -6px -2px 16px 0px;
}
@media screen and (max-width: 998px){
  .contactCard>div{
    width: calc(50% - 40px);
  }
}
@media screen and (max-width: 768px){
  .contactCard{
    top: 0;
    margin-bottom: 25px;
    flex-direction: column;
  }
  .contactCard>div{
    width: 100%;
  }
  .contactCard>div.mail{
    width: 100%;
  }
}
.contactCard .title_top{
  margin-bottom: 0;
  font-size: 1.4rem;
}
.contactCard a,
.contactCard p{
  font-size: 1.3rem;
  line-height: 1.3rem;
  margin-bottom: 10px;
}
</style>